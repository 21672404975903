import { Dispatch } from "redux"

import { Api } from "api"
import { setClientCount, setClientLimit } from "store/layout/layoutActions"

export const fetchClientLimit = () => async (dispatch: Dispatch) => {
  try {
    const {
      result: { clientCount, clientLimit }
    } = await Api.getClientLimit()

    dispatch(setClientCount(clientCount))
    dispatch(setClientLimit(clientLimit))
  } catch (err) {
    console.error("An error occurred during fetching Client Limit: ", err)
  }
}
