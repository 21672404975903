import { redirect } from "react-router-dom"
import { Dispatch } from "redux"

import { Api } from "api"
import { TourEnum } from "enums"
import { AccountingOfficeStageEnum } from "enums/tour"
import { AccountingOffice } from "types/AccountingOffice"

export const startTestTour = () => async (dispatch: Dispatch) => {
  try {
    await Api.moveNextStage()
    const res = await Api.fetchAccouningOffice()
    if (
      res.result.accountingOfficeConfigurationStage ===
      AccountingOfficeStageEnum.Done
    ) {
      dispatch({
        type: "accountingOffice/SET_VALUE",
        resolved: "SET_VALUE",
        payload: {
          field: "accountingOfficeConfigurationStage",
          value: "Done"
        }
      })
    } else {
      dispatch(startTestSendTour())
    }
  } catch (err) {
    redirect("/")
  }
}

export const openTour = () =>
  ({
    type: "tour/OPEN_TOUR"
  }) as const

const startTestSendTour = () =>
  ({
    type: "tour/START_TEST_SEND_TOUR"
  }) as const

export const setTourType = (tourType: TourEnum | null) =>
  ({
    type: "tour/SET_TOUR_TYPE",
    tourType
  }) as const

export const clearTourData = () =>
  ({
    type: "tour/CLEAR_TOUR_DATA"
  }) as const

export type TourAction =
  | ReturnType<
      | typeof openTour
      | typeof startTestSendTour
      | typeof setTourType
      | typeof clearTourData
    >
  | {
      type: "accountingOffice/FETCH"
      resolved: "SUCCESS"
      payload: AccountingOffice
    }
