export const BORG_ID = "borg_id"

export const HIDE_DISCLAIMER = "hide_disclaimer"

export const SESSION = "session_adiutor"

export const BORG_LOGIN_URL = "borg_login_url"

export const IGNORE_EXCEEDED_LIMIT = "ignore_exceeded_limit"

export const NEWS_FILTERS = "news_filters"
