import { cva, type VariantProps } from "class-variance-authority"
import * as React from "react"

import Icon from "components/Icons"
import { cn } from "lib/utils"

export interface AlertBarProps {
  variant: "default" | "destructive" | "warning"
  message: React.ReactNode | string
  onClose?: () => void
}

const alertVariants = cva(
  "relative w-full border p-4 text-center font-bold [&>svg~*]:pl-7 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground",
  {
    variants: {
      variant: {
        default: "bg-background text-foreground",
        destructive:
          "border-none bg-destructive/30 text-destructive dark:border-destructive [&>svg]:text-destructive",
        warning:
          "border-none bg-warning text-warning-foreground dark:border-warning [&>svg]:text-warning-foreground"
      }
    },
    defaultVariants: {
      variant: "default"
    }
  }
)

const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({ className, variant, ...props }, ref) => (
  <div
    ref={ref}
    role="alert"
    className={cn(alertVariants({ variant }), className)}
    {...props}
  />
))
Alert.displayName = "Alert"

const AlertTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h5
    ref={ref}
    className={cn("mb-1 font-medium leading-none tracking-tight", className)}
    {...props}
  />
))
AlertTitle.displayName = "AlertTitle"

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("relative text-sm [&_p]:leading-relaxed", className)}
    {...props}
  />
))
AlertDescription.displayName = "AlertDescription"

const AlertBar = ({ variant, message, onClose }: AlertBarProps) => {
  return (
    <Alert variant={variant}>
      <AlertDescription>
        {message}
        {onClose && (
          <Icon
            onClick={onClose}
            type="close"
            className="absolute right-4 top-0 h-5 w-5 cursor-pointer hover:opacity-70"
          />
        )}
      </AlertDescription>
    </Alert>
  )
}

export { Alert, AlertTitle, AlertDescription, AlertBar }
