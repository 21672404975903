import { request, serialize } from "api/config"
import { NewsFiltersPayload } from "pages/NewsPages/Filters/types"
import { UserProfile } from "types/Account"
import {
  AccountingOffice,
  AOGUS,
  CreateNewsAORequest,
  SmtpTestSettings,
  UpdateNewsAORequest
} from "types/AccountingOffice"
import { PaginationRequest, ResultModel } from "types/ApiResponse"
import { ClientLimit } from "types/Client"
import { NewsAO, NewsAOStatus } from "types/News"
import {
  NewslettersPreviewDto,
  NewslettersPreviewResponseDto
} from "types/Newsletter"
import { showErrorNotification } from "utils/errors"

const PATH_NAME = "AccountingOffice"

export const fetchAccouningOffice = async (): Promise<
  ResultModel<AccountingOffice>
> =>
  request.get(`${PATH_NAME}`).catch((err) => {
    showErrorNotification(
      err,
      "Wystąpił błąd podczas pobierania danych. Jeżeli problem będzie się powtarzał, prosimy o kontakt z pomocą techniczną."
    )
  })

export const updateAccouningOffice = async (
  payload: Partial<AccountingOffice>
): Promise<ResultModel<AccountingOffice>> => request.patch(PATH_NAME, payload)

export const fetchNewsAO = async (
  pagination: PaginationRequest,
  status?: NewsAOStatus,
  onlyWhenHaveClients?: boolean
): Promise<ResultModel<NewsAO[]>> =>
  request
    .get(
      `${PATH_NAME}/News${serialize({
        status,
        ...pagination,
        onlyWhenHaveClients
      })}`
    )
    .catch((err) => {
      showErrorNotification(
        err,
        "Wystąpił błąd podczas pobierania newsów. Jeżeli problem będzie się powtarzał, prosimy o kontakt z pomocą techniczną."
      )
    })

export const searchNewsAO = async (
  pagination: PaginationRequest,
  phrase: string,
  filters?: NewsFiltersPayload,
  status?: NewsAOStatus
): Promise<ResultModel<NewsAO[]>> =>
  request
    .patch(
      `${PATH_NAME}/News/Search${serialize({
        phrase,
        status,
        ...pagination
      })}`,
      filters
    )
    .catch((err) => {
      showErrorNotification(
        err,
        "Wystąpił błąd podczas pobierania newsów. Jeżeli problem będzie się powtarzał, prosimy o kontakt z pomocą techniczną."
      )
    })

export const fetchNewsAOById = async (
  id: string
): Promise<ResultModel<NewsAO>> => request.get(`${PATH_NAME}/News/${id}`)

export const addNewsAO = async (
  news: CreateNewsAORequest
): Promise<ResultModel<NewsAO>> => request.post(`${PATH_NAME}/News`, news)

export const updateNewsAO = async (
  news: UpdateNewsAORequest
): Promise<ResultModel<NewsAO>> => {
  const { id, ...rest } = news
  return request.put(`${PATH_NAME}/News/${id}`, rest)
}

export const cloneWkNews = async (
  id: string,
  news: CreateNewsAORequest
): Promise<ResultModel<NewsAO>> =>
  request.patch(`${PATH_NAME}/News/CloneWkNews/${id}`, news)

export const getPreviewNewsletters = async (
  newsIdsSorted: string[],
  receiverIds: string[] | null
): Promise<ResultModel<NewslettersPreviewResponseDto>> =>
  request.patch(`${PATH_NAME}/GetPreviewNewsletters`, {
    newsIdsSorted,
    receiverIds
  })

export const sendNewsletter = async (
  newsletter: NewslettersPreviewDto
): Promise<ResultModel<null>> =>
  request.post(`${PATH_NAME}/SendNewsletter`, newsletter)

export const archiveNewsAO = async (id: string): Promise<ResultModel<NewsAO>> =>
  request.patch(`${PATH_NAME}/News/${id}/Archive`)

export const restoreNewsAO = async (id: string): Promise<ResultModel<NewsAO>> =>
  request.patch(`${PATH_NAME}/News/${id}/ToNew`)

export const fetchUserProfile = async (): Promise<ResultModel<UserProfile>> =>
  request.get("UserProfile")

export const updateUserProfile = async (
  payload: UserProfile
): Promise<ResultModel<UserProfile>> => request.patch("UserProfile", payload)

export const getAliasValidation = async (
  replyToEmailAlias: string
): Promise<ResultModel<boolean>> =>
  request.get(
    `${PATH_NAME}/IsReplyToEmailAliasOccupied${serialize({
      replyToEmailAlias
    })}`
  )

export const getAOFromGUSbyNIP = async (
  nip: string
): Promise<ResultModel<AOGUS>> =>
  request.get(`${PATH_NAME}/DownloadData/${nip}`)

export const getClientLimit = async (): Promise<ResultModel<ClientLimit>> =>
  request.get(`${PATH_NAME}/ClientLimit`)

export const testSmtpSettings = async (
  payload: SmtpTestSettings
): Promise<ResultModel<boolean>> =>
  request.post(`${PATH_NAME}/TestSmtpSettings`, payload)
