import React from "react"

import { useToast } from "components/ui/use-toast"
import { showErrorNotification } from "utils/errors"
import usePrefix from "utils/usePrefix"

export function useCopyToClipboard() {
  const t = usePrefix("General")
  const { toast } = useToast()

  return React.useCallback(
    async (text: string | number) => {
      try {
        await navigator.clipboard.writeText(text.toString())
        toast({
          title: t("copyToClipboardTitle"),
          description: t("copyToClipboardDescription")
        })
      } catch (err) {
        showErrorNotification(err, t("copyToClipboardFailed"))
      }
    },
    [t, toast]
  )
}
