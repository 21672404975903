import { ColumnDef } from "@tanstack/react-table"
import React, { useCallback, useEffect, useState } from "react"

import { Api } from "api"
import SessionManager from "api/SessionManager"
import { GenericTable } from "components/Table/GenericTable"
import { DialogComponent } from "components/ui/dialog"
import { Switch } from "components/ui/switch"
import {
  NotificationSettings,
  NotificationSettingsTable
} from "types/Notification"
import { showErrorNotification } from "utils/errors"
import usePrefix from "utils/usePrefix"

interface NotificationsModalProps {
  onClose: () => void
}

export const NotificationsModal: React.FC<NotificationsModalProps> = ({
  onClose
}) => {
  const t = usePrefix("NotificationSettings")
  const isAoPublisher = SessionManager.getIsAOPublisher()
  const [isLoading, setLoading] = useState(false)

  const [data, setData] = useState<NotificationSettingsTable[]>([])

  const getNotifications = useCallback(async () => {
    setLoading(true)
    try {
      const { result } = await Api.getNotificationSettings()
      const data = isAoPublisher
        ? [
            {
              id: 1,
              email: result.newNewsEmailAllowed,
              inapp: result.newNewsAppAllowed
            },
            {
              id: 2,
              email: result.clientUnsubEmailAllowed,
              inapp: result.clientUnsubAppAllowed
            },
            {
              id: 3,
              email: result.clientEmailHardBouncedEmailAllowed,
              inapp: result.clientEmailHardBouncedAppAllowed
            },
            {
              id: 4,
              email: result.applicationNewFeaturesEmailAllowed,
              inapp: result.applicationNewFeaturesAppAllowed
            }
          ]
        : [
            {
              id: 4,
              email: result.applicationNewFeaturesEmailAllowed,
              inapp: result.applicationNewFeaturesAppAllowed
            }
          ]
      setData(data)
    } catch (err) {
      showErrorNotification(err, "Błąd pobierania ustawień")
    } finally {
      setLoading(false)
    }
  }, [isAoPublisher])

  useEffect(() => {
    getNotifications()
  }, [getNotifications])

  const rowHeaders = isAoPublisher
    ? [
        t("new_news"),
        t("off_subscription_by_client"),
        t("incorect_email_or_send"),
        t("new_app_version")
      ]
    : [t("new_app_version")]

  const setEmail = (index: number, isChecked: boolean) => {
    const newData = [...data]

    setData(
      newData.map((el, ind) =>
        index === ind ? { ...el, email: isChecked } : el
      )
    )
  }

  const setInapp = (index: number, isChecked: boolean) => {
    const newData = [...data]

    setData(
      newData.map((el, ind) =>
        index === ind ? { ...el, inapp: isChecked } : el
      )
    )
  }

  const handleSave = async () => {
    try {
      const payload: NotificationSettings = isAoPublisher
        ? {
            newNewsEmailAllowed: data[0].email,
            newNewsAppAllowed: data[0].inapp,
            clientUnsubEmailAllowed: data[1].email,
            clientUnsubAppAllowed: data[1].inapp,
            clientEmailHardBouncedEmailAllowed: data[2].email,
            clientEmailHardBouncedAppAllowed: data[2].inapp,
            applicationNewFeaturesEmailAllowed: data[3].email,
            applicationNewFeaturesAppAllowed: data[3].inapp
          }
        : {
            applicationNewFeaturesEmailAllowed: data[0].email,
            applicationNewFeaturesAppAllowed: data[0].inapp
          }

      await Api.saveNotificationSettings(payload)
    } catch (err) {
      showErrorNotification(err, "Wystąpił błąd")
    }
    onClose()
  }

  const columns: ColumnDef<NotificationSettingsTable>[] = [
    {
      id: "rowHeaders",
      accessorKey: "rowHeaders",
      header: t("type_notification_label"),

      cell: (info) => rowHeaders[info.row.index]
    },
    {
      id: "email",
      accessorKey: "email",
      header: t("email_label"),

      cell: (info) => (
        <Switch
          checked={info.row.original.email}
          onCheckedChange={(checked) => setEmail(info.row.index, checked)}
        />
      )
    },
    {
      id: "inapp",
      accessorKey: "inapp",
      header: t("in_app_label"),

      cell: (info) => (
        <Switch
          checked={info.row.original.inapp}
          onCheckedChange={(checked) => setInapp(info.row.index, checked)}
        />
      )
    }
  ]

  return (
    <DialogComponent
      open
      onOpenChange={onClose}
      className="flex max-w-[600px] flex-col"
      title={t("header")}
      onCancel={onClose}
      onConfirm={handleSave}
    >
      <GenericTable
        data={data}
        columns={columns}
        isLoading={isLoading}
        className="max-w-[560px]"
      />
    </DialogComponent>
  )
}
