import { AppState } from "../AppState"

export const selectBottomBarStatus = (s: AppState) => s.layout.withBottomBar

export const selectNavBarStatus = (s: AppState) => s.layout.withNavBar

export const selectClientCount = (s: AppState) => s.layout.clientCount

export const selectClientLimit = (s: AppState) => s.layout.clientLimit

export const selectIsFiltersVisible = (s: AppState) => s.layout.isFiltersVisible
