import React from "react"
import Select, {
  components as reactSelectComponents,
  NoticeProps,
  Props as SelectProps
} from "react-select"

import usePrefix from "utils/usePrefix"

export interface SingleSelectOption {
  value: string | number
  label: string | number
  icon?: React.ReactNode
  optionDisabled?: boolean
}

type SingleSelectProps = SelectProps<SingleSelectOption>

const SingleSelect: React.FC<SingleSelectProps> = React.memo(
  ({
    hideSelectedOptions = false,
    isSearchable = false,
    isClearable = false,
    placeholder,
    value,
    components,
    className,
    ...props
  }) => {
    const t = usePrefix("General")

    const NoOptionsMessage = (props: NoticeProps<SingleSelectOption>) => {
      return (
        <reactSelectComponents.NoOptionsMessage {...props}>
          {t("no_data")}
        </reactSelectComponents.NoOptionsMessage>
      )
    }

    return (
      <Select
        {...props}
        placeholder={placeholder || t("search")}
        isMulti={false}
        hideSelectedOptions={hideSelectedOptions}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isOptionDisabled={(option) => !!option.optionDisabled}
        value={value}
        components={{
          ...components,
          NoOptionsMessage
        }}
        className={`my-react-select-container ${className}`}
        classNamePrefix="my-react-select"
      />
    )
  }
)

export default SingleSelect
