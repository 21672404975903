import { RoleEnum } from "enums"

/**
 * @param Unknown - nieznany
 * @param AO_User - Księgowy biura rachunkowego
 * @param AO_Publisher - Główny księgowy biura rachunkowego
 * @param WK_Editor - Redaktor Wolters Kluwer
 * @param WK_Publisher - Wydawca Wolters Kluwer (posiadający uprawnienia do publikowania treści)
 */
export type Role = "AO_User" | "AO_Publisher" | "WK_Editor" | "WK_Publisher"

export const dashboardRouteRewriter: Record<RoleEnum, string> & {
  UNKNOWN: string
} = {
  AO_User: "/",
  AO_Publisher: "/",
  WK_Editor: "/news/working",
  WK_Publisher: "/news/working",
  UNKNOWN: "/"
}
