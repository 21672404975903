import { FormikProps } from "formik"
import React from "react"
import { useSelector } from "react-redux"
import { MultiValue, SingleValue } from "react-select"

import SessionManager from "api/SessionManager"
import FormCheckbox from "components/Form/FormCheckbox"
import FormFieldWrapper from "components/Form/FormFieldWrapper"
import Icon from "components/Icons"
import SingleSelect, {
  SingleSelectOption
} from "components/Inputs/Select/SingleSelect"
import { Header } from "components/Text"
import { TooltipComponent } from "components/ui/tooltip"
import { TagEnum } from "enums"
import { selectFormActivityList } from "store/formActivity"
import { selectLabelList } from "store/label"
import { Tags } from "types/Template"
import usePrefix from "utils/usePrefix"

import { CustomTagSelect } from "./CustomTagSelect"
import { PkdSelect } from "./PkdSelect"
import { TagGroup } from "./TagGroup"

interface TagSectionProps {
  formType: "news" | "client"
  disabled?: boolean
  isTagValidationDisabled?: boolean
}

export const TagSection = <T extends Tags>({
  formType,
  disabled = false,
  isTagValidationDisabled,
  ...formikProps
}: FormikProps<T> & TagSectionProps) => {
  const t = usePrefix("Tags")
  const allTags = useSelector(selectLabelList)
  const allActivityForms = useSelector(selectFormActivityList)
  const isAORole = SessionManager.getAORole()
  const realm = SessionManager.getRealm()

  const {
    onActivityFormChange,
    onPkdChange,
    onTagsChange,
    onCustomTagsChange
  } = useTagSetField(formikProps, formType, disabled)

  const {
    values: {
      pkds,
      tags,
      customTags,
      isTagValidationOff,
      logicalAlternativeTags,
      logicalAlternativeCustomTags,
      logicalAlternativePkds
    },
    touched,
    errors
  } = formikProps

  return (
    <>
      <Header
        className={touched.tags && errors.tags ? "ant-form-item-has-error" : ""}
      >
        <div className="ant-form-item-label" style={{ width: "100%" }}>
          <label className="ant-form-item-required">
            {t("section_tags_label")}
          </label>
        </div>
      </Header>
      <div className="flex flex-col space-y-4">
        {!isTagValidationDisabled && (
          <div className="space-y-4">
            <FormFieldWrapper
              name="activityFormCode"
              label={t("activity_form_label")}
            >
              <SingleSelect
                name="activityFormCode"
                placeholder={t("select_placeholder")}
                isMulti={false}
                value={allActivityForms
                  ?.map(({ value, label }) => ({
                    value,
                    label
                  }))
                  .find(
                    (option) =>
                      Number(option.value) ===
                      formikProps.values.activityFormCode
                  )}
                onChange={onActivityFormChange}
                options={allActivityForms}
                isDisabled={disabled}
                className="w-full"
              />
            </FormFieldWrapper>

            <PkdSelect
              pkds={pkds || []}
              logicalAlternativePkds={logicalAlternativePkds || []}
              formType={formType}
              isDisabled={!!disabled}
              setValues={onPkdChange}
            />
          </div>
        )}

        {realm === "Accountancy" && formType === "client" && (
          <div className="flex items-center space-y-4 text-sm">
            <FormCheckbox
              id="isTagValidationOff"
              name="isTagValidationOff"
              disabled={isTagValidationDisabled}
              label={
                <div className="flex items-center gap-2">
                  {t("tag_validation_checkbox")}
                  <TooltipComponent
                    triggerContent={
                      <span>
                        <Icon
                          type="infoRound"
                          className="h-4 w-4 stroke-secondary-foreground"
                        />
                      </span>
                    }
                    tooltipContent={t("tag_validation_checkbox_tooltip")}
                  />
                </div>
              }
            />
          </div>
        )}

        <div className="space-y-4" data-tour="add-client-step-3">
          {Object.values(allTags)?.map((tagGroup, index) => (
            <TagGroup
              key={tagGroup.header ?? `group-${index}`}
              tags={tagGroup}
              selected={tags}
              selectedAlternative={logicalAlternativeTags}
              onChange={onTagsChange}
              formType={formType}
              isTagValidationOff={isTagValidationOff}
            />
          ))}
          {isAORole && (
            <CustomTagSelect
              selectedTags={customTags || []}
              selectedAlternative={logicalAlternativeCustomTags || []}
              onChange={onCustomTagsChange}
              formType={formType}
            />
          )}
          {touched.tags && (
            <div className="absolute mb-2 text-xs text-destructive">
              {errors.tags as string}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const useTagSetField = <T extends Tags>(
  formikProps: FormikProps<T>,
  formType: "news" | "client",
  disabled?: boolean
) => {
  const { values, setValues, setFieldTouched } = formikProps

  const onActivityFormChange = async (
    option: SingleValue<SingleSelectOption> | MultiValue<SingleSelectOption>
  ) => {
    let newTags = [...(values.tags || [])]

    if (formType === "news" && values.tags?.includes(TagEnum.General)) {
      newTags = []
    }

    await setFieldTouched("tags", true)
    await setValues({
      ...values,
      activityFormCode:
        (option as SingleValue<SingleSelectOption>)?.value || null,
      tags: newTags
    })
  }

  const onPkdChange = (pkds: string[], logicalAlternativePkds: string[]) => {
    setValues({
      ...values,
      tags:
        values.tags?.includes(TagEnum.General) && formType === "news"
          ? []
          : values.tags,
      pkds,
      logicalAlternativePkds
    })
  }

  const onTagsChange = async (
    tags: number[],
    logicalAlternativeTags?: number[]
  ) => {
    if (disabled) return

    setFieldTouched("tags", true)
    setValues({
      ...values,
      activityFormCode:
        formType === "news" && tags.includes(TagEnum.General)
          ? null
          : values.activityFormCode,
      tags,
      customTags:
        formType === "news" && tags.includes(TagEnum.General)
          ? []
          : values.customTags,
      pkds:
        formType === "news" && tags.includes(TagEnum.General)
          ? []
          : values.pkds,
      logicalAlternativeTags: logicalAlternativeTags,
      logicalAlternativeCustomTags:
        formType === "news" && tags.includes(TagEnum.General)
          ? []
          : values.logicalAlternativeCustomTags,
      logicalAlternativePkds:
        formType === "news" && tags.includes(TagEnum.General)
          ? []
          : values.logicalAlternativePkds
    })
  }

  const onCustomTagsChange = async (
    customTags: string[],
    logicalAlternativeCustomTags?: string[]
  ) => {
    if (disabled) return
    setFieldTouched("tags", true)
    setValues({
      ...values,
      tags:
        formType === "news" && values.tags?.includes(TagEnum.General)
          ? []
          : values.tags,
      customTags,
      logicalAlternativeCustomTags
    })
  }

  return {
    onActivityFormChange,
    onPkdChange,
    onTagsChange,
    onCustomTagsChange
  }
}
