import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

import { Api } from "api"
import SessionManager from "api/SessionManager"
import { MiddleContainer } from "components/AppLayout/PageContainer"
import { AuthorAvatar } from "components/AuthorAvatar"
import { Button } from "components/Button"
import { IconName } from "components/Icons"
import UserNotifications from "components/UserNotifications"
import { AlertDialogComponent } from "components/ui/alert-dialog"
import { RoleEnum, TourEnum } from "enums"
import { clearTourData, selectTestModeStatus, setTourType } from "store/tour"
import { dashboardRouteRewriter } from "types/Role"
import { useExceededClientsLimit } from "utils/useExceededClientsLimitData"
import usePrefix from "utils/usePrefix"

import { AccountDrawer } from "./AccountDrawer"
import { AppNavigation } from "./AppNavigation"

export const PageHeader = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const t = usePrefix("General")

  const role = SessionManager.getUserRole()
  const isAOrole = SessionManager.getAORole()
  const userFullName = SessionManager.getUserFullName()
  const isTestMode = useSelector(selectTestModeStatus)
  const { exceededClientsLimit, clientCount, clientLimit } =
    useExceededClientsLimit()
  const [isAccountDrawerOpen, setAccountDrawerStatus] = useState(false)

  const toggleAccountDrawer = () => setAccountDrawerStatus(!isAccountDrawerOpen)

  const handleAddNewsletterClick = async () => {
    if (isTestMode) {
      try {
        await Api.moveNextStage()
        dispatch(setTourType(null))
        dispatch(setTourType(TourEnum.SELECT_TEST_NEWS_PAGE))
      } catch (err) {}
    }
  }

  const handleExitTestTour = async () => {
    try {
      await Api.disposeTestDispatch()
      dispatch(clearTourData())
      navigate("/")
    } catch (err) {}
  }

  const commonAORoutes =
    role === "AO_User" || role === "AO_Publisher"
      ? [
          {
            label: t("nav_main_page"),
            route: dashboardRouteRewriter[role],
            iconType: "home" as IconName,
            datagtm: "gtm-main-nav-bar-main-page"
          },
          {
            label: t("nav_news"),
            route: "/news/ao",
            iconType: "news" as IconName,
            datagtm: "gtm-main-nav-bar-news",
            datatour: "main-page-step-news-nav"
          },
          {
            label: t("nav_sent_bulletins"),
            route: "/newsletter/sent",
            iconType: "send" as IconName,
            datagtm: "gtm-main-nav-bar-sent-bulletins",
            datatour: "main-page-step-sent-nav"
          },
          {
            label: t("nav_confirmations"),
            route: "/confirmations",
            iconType: "mailSent" as IconName,
            datagtm: "gtm-main-nav-bar-confirmations",
            datatour: "main-page-step-confirmations-nav"
          },
          {
            label: clientLimit
              ? `${t("nav_clients")} ${clientCount}/${clientLimit}`
              : t("nav_clients"),
            route: "/client",
            iconType: "users" as IconName,
            datagtm: "gtm-main-nav-bar-clients",
            datatour: "main-page-step-clients-nav"
          }
        ]
      : []

  const getNavigationProps = () => {
    if (!role) {
      return null
    }

    switch (role) {
      case RoleEnum.AO_User:
        return { routes: commonAORoutes }
      case RoleEnum.AO_Publisher:
        return {
          routes: commonAORoutes,
          ctaBtn: {
            label: t("create_bulletin_btn"),
            route: "/newsletter/add",
            iconType: "plus" as IconName,
            datagtm: "gtm-main-nav-bar-create-bulletin",
            datatour: "main-page-step-bulletin-cta",
            onClick: handleAddNewsletterClick,
            disabled: exceededClientsLimit
          }
        }
      case RoleEnum.WK_Editor:
      case RoleEnum.WK_Publisher:
        return {
          routes: [
            {
              label: t("nav_main_page"),
              route: dashboardRouteRewriter[role],
              datagtm: "gtm-main-nav-bar-main-page",
              iconType: "home" as IconName
            },
            {
              label: t("nav_to_publish"),
              route: "/news/toPublish",
              datagtm: "gtm-main-nav-bar-to-publish",
              iconType: "highlight" as IconName
            },
            {
              label: t("nav_published"),
              route: "/news/published",
              datagtm: "gtm-main-nav-bar-published",
              iconType: "news" as IconName
            },
            {
              label: "Licencje klientów",
              route: "/license",
              datagtm: "gtm-main-nav-bar-license",
              iconType: "users" as IconName
            }
          ],
          ctaBtn: {
            label: t("add_news_btn"),
            route: "/news/add",
            datagtm: "gtm-main-nav-bar-add-news",
            iconType: "plus" as IconName
          }
        }
      default:
        return null
    }
  }

  const navProps = getNavigationProps()

  return (
    <>
      {isTestMode && (
        <div>
          <MiddleContainer>
            <div>
              <div className="flex-[2]">{t("test_mode_app_header")}</div>
              <div className="flex-[2]">
                <AlertDialogComponent
                  title={t("test_mode_tooltip_title")}
                  onConfirm={handleExitTestTour}
                  okText={t("yes")}
                  cancelText={t("no")}
                  trigger={<Button variant="outline" iconType="close" />}
                />
              </div>
            </div>
          </MiddleContainer>
        </div>
      )}
      <div className="sticky top-0 z-50 bg-primary">
        <MiddleContainer className="px-4">
          <div className="flex h-[50px] justify-between">
            <div className="flex items-center text-2xl">
              <Link
                to={
                  role
                    ? dashboardRouteRewriter[role]
                    : dashboardRouteRewriter.UNKNOWN
                }
                className="flex items-center gap-2 text-white"
              >
                <img
                  className="h-[35px] w-[35px]"
                  src="https://update.lex.pl/taxalert/pixel.svg"
                  alt="Logo img"
                />
                {t("app_name")}
              </Link>
            </div>

            <div className="relative flex items-center gap-4">
              {isAOrole && <UserNotifications />}
              {role === RoleEnum.WK_Publisher && (
                <Link to="/notifications">
                  <Button
                    variant="link"
                    iconType="notificationOn"
                    className="text-white hover:text-white/70"
                  >
                    {t("notifications")}
                  </Button>
                </Link>
              )}

              <AuthorAvatar
                fullName={userFullName}
                withBurger
                onClick={toggleAccountDrawer}
              />
            </div>
          </div>
        </MiddleContainer>
      </div>

      {navProps && <AppNavigation {...navProps} />}

      <AccountDrawer
        isOpen={isAccountDrawerOpen}
        onClose={() => setAccountDrawerStatus(false)}
      />
    </>
  )
}
