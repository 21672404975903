import * as React from "react"
import { SingleValue } from "react-select"

import Icon from "components/Icons"
import SingleSelect, {
  SingleSelectOption
} from "components/Inputs/Select/SingleSelect"
import { FieldValueUpdate } from "components/Modal/AutoNewsletterDelivery/AutoNewsletterDeliveryForm"
import { Checkbox } from "components/ui/checkbox"
import { TooltipComponent } from "components/ui/tooltip"
import {
  AutoNewsletterDeliveryValues,
  options
} from "types/AutoNewsletterDelivery"
import usePrefix from "utils/usePrefix"

type IgnoreNewsConfigProps = {
  values: AutoNewsletterDeliveryValues
  onChange: FieldValueUpdate
}

export const IgnoreNewsConfig = ({
  values,
  onChange
}: IgnoreNewsConfigProps) => {
  const t = usePrefix("AutoDelivery")

  const { ignoreNews, amountDaysToIgnore } = values

  return (
    <div className="flex bg-accent p-4">
      <div className="m-auto pr-4">
        <Checkbox
          name="ignoreNews"
          checked={ignoreNews}
          onCheckedChange={(checked) => onChange("ignoreNews", checked)}
        />
      </div>
      <div
        className="text-secondary-foreground"
        style={{ lineHeight: "24px", fontSize: "14px" }}
      >
        <div className="inline">{t("ignoreNewsConfig")}</div>
        <div className="mx-1.5 inline-flex w-20">
          <SingleSelect
            isSearchable={false}
            onChange={(value) =>
              onChange(
                "amountDaysToIgnore",
                (value as SingleValue<SingleSelectOption>)?.value
              )
            }
            value={
              options.daysToIgnore
                .map((dayToIgnore) => ({
                  value: dayToIgnore,
                  label: dayToIgnore
                }))
                .find((option) => option.value === amountDaysToIgnore) ||
              undefined
            }
            options={options.daysToIgnore.map((toIgnore) => ({
              value: toIgnore,
              label: toIgnore
            }))}
          />
        </div>
        <div className="inline-flex items-center gap-1">
          {t("ignoreNewsContConfig", {
            postProcess: "interval",
            count: amountDaysToIgnore
          })}
          <TooltipComponent
            tooltipContent={t("tooltipSkip2Days")}
            triggerContent={
              <span>
                <Icon
                  type="infoRound"
                  className="h-4 w-4 stroke-secondary-foreground"
                />
              </span>
            }
          />
        </div>
      </div>
    </div>
  )
}
