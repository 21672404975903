import {
  Angry,
  Menu,
  Tag,
  ArchiveX,
  ArrowLeft,
  ArrowRight,
  Bell,
  BellOff,
  Check,
  ChevronDown,
  CircleArrowDown,
  CircleCheck,
  CircleX,
  CircleHelp,
  Eye,
  Clipboard,
  History,
  Files,
  FileText,
  FileX,
  Filter,
  Flame,
  Globe,
  Highlighter,
  House,
  Info,
  Loader,
  LogOut,
  Mail,
  MailOpen,
  MailX,
  Meh,
  MessageSquareText,
  MonitorDot,
  Newspaper,
  Pencil,
  Phone,
  Pipette,
  Plus,
  Printer,
  RotateCcw,
  Send,
  Settings,
  Smartphone,
  Smile,
  Tablet,
  Trash2,
  Users,
  X,
  CircleArrowUp,
  User,
  CircleAlert,
  ChevronUp,
  LucideProps,
  ArrowUpDown,
  PackageOpen,
  PackageX,
  CloudUpload,
  Undo2,
  GripHorizontal
} from "lucide-react"
import React from "react"

export type IconName =
  | "angry"
  | "archiveX"
  | "arrowUpDown"
  | "call"
  | "chat"
  | "check"
  | "checkCircle"
  | "xCircle"
  | "chevronUp"
  | "chevronDown"
  | "close"
  | "clipboard"
  | "delete"
  | "desktop"
  | "downOutlined"
  | "dropper"
  | "edit"
  | "eye"
  | "filePdf"
  | "files"
  | "filter"
  | "fire"
  | "globe"
  | "highlight"
  | "history"
  | "home"
  | "infoRound"
  | "leftArrow"
  | "loading"
  | "logout"
  | "mail"
  | "mailCrossed"
  | "mailSent"
  | "menu"
  | "mobile"
  | "move"
  | "neutral"
  | "news"
  | "noFile"
  | "notificationOff"
  | "notificationOn"
  | "packageOpen"
  | "plus"
  | "print"
  | "question"
  | "return"
  | "rightArrow"
  | "send"
  | "settings"
  | "smile"
  | "tablet"
  | "tag"
  | "undo2"
  | "upOutlined"
  | "uploadCloud"
  | "user"
  | "users"
  | "warning"

interface Props extends Omit<LucideProps, "ref"> {
  type: IconName
}

const ICON_SIZE = "16"

const Icon: React.FC<Props> = ({
  type,
  color,
  width = ICON_SIZE,
  height = ICON_SIZE,
  stroke = "hsl(var(--muted-foreground))",
  strokeWidth = 1.5,
  ...props
}) => {
  const params = { color, stroke, strokeWidth, width, height }

  return {
    archiveX: <ArchiveX {...params} {...props} />,
    angry: <Angry {...params} {...props} />,
    arrowUpDown: <ArrowUpDown {...params} {...props} />,
    call: <Phone {...params} {...props} />,
    chat: <MessageSquareText {...params} {...props} />,
    check: <Check {...params} {...props} />,
    checkCircle: <CircleCheck {...params} {...props} />,
    xCircle: <CircleX {...params} {...props} />,
    chevronUp: <ChevronUp {...params} {...props} />,
    chevronDown: <ChevronDown {...params} {...props} />,
    close: <X {...params} {...props} />,
    clipboard: <Clipboard {...params} {...props} />,
    delete: <Trash2 {...params} {...props} />,
    downOutlined: <CircleArrowDown {...params} {...props} />,
    dropper: <Pipette {...params} {...props} />,
    edit: <Pencil {...params} {...props} />,
    eye: <Eye {...params} {...props} />,
    filePdf: <FileText {...params} {...props} />,
    files: <Files {...params} {...props} />,
    filter: <Filter {...params} {...props} />,
    fire: <Flame {...params} {...props} />,
    globe: <Globe {...params} {...props} />,
    highlight: <Highlighter {...params} {...props} />,
    history: <History {...params} {...props} />,
    home: <House {...params} {...props} />,
    infoRound: <Info {...params} {...props} />,
    desktop: <MonitorDot {...params} {...props} />,
    leftArrow: <ArrowLeft {...params} {...props} />,
    loading: <Loader {...params} {...props} />,
    logout: <LogOut {...params} {...props} />,
    mail: <Mail {...params} {...props} />,
    mailCrossed: <MailX {...params} {...props} />,
    mailSent: <MailOpen {...params} {...props} />,
    menu: <Menu {...params} {...props} />,
    move: <GripHorizontal {...params} {...props} />,
    neutral: <Meh {...params} {...props} />,
    news: <Newspaper {...params} {...props} />,
    noFile: <FileX {...params} {...props} />,
    notificationOff: <BellOff {...params} {...props} />,
    notificationOn: <Bell {...params} {...props} />,
    mobile: <Smartphone {...params} {...props} />,
    packageOpen: <PackageOpen {...params} {...props} />,
    packageX: <PackageX {...params} {...props} />,
    plus: <Plus {...params} {...props} />,
    print: <Printer {...params} {...props} />,
    question: <CircleHelp {...params} {...props} />,
    return: <RotateCcw {...params} {...props} />,
    rightArrow: <ArrowRight {...params} {...props} />,
    send: <Send {...params} {...props} />,
    settings: <Settings {...params} {...props} />,
    smile: <Smile {...params} {...props} />,
    tablet: <Tablet {...params} {...props} />,
    tag: <Tag {...params} {...props} />,
    undo2: <Undo2 {...params} {...props} />,
    upOutlined: <CircleArrowUp {...params} {...props} />,
    uploadCloud: <CloudUpload {...params} {...props} />,
    user: <User {...params} {...props} />,
    users: <Users {...params} {...props} />,
    warning: <CircleAlert {...params} {...props} />
  }[type]
}

export default Icon
