import { request } from "api/config"
import { ResultModel } from "types/ApiResponse"
import {
  AutoNewsletterDeliveryType,
  AutoNewsletterDeliveryValues
} from "types/AutoNewsletterDelivery"

const PATH_NAME = "AutoNewsletterDelivery"

export const getAutoNewsletterDelivery = async (): Promise<
  ResultModel<AutoNewsletterDeliveryType>
> => request.get(PATH_NAME)

export const updateAutoNewsletterDelivery = async (
  payload: Partial<AutoNewsletterDeliveryValues>
): Promise<ResultModel<AutoNewsletterDeliveryType>> =>
  request.patch(PATH_NAME, payload)
