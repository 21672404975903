import React from "react"

import { Button } from "components/Button/Button"
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem
} from "components/ui/dropdown-menu"
type DropdownButtonProps = {
  items: Array<{ label: string; key: string }>
  onSelect: (key: string) => void
  children: React.ReactNode
}

export const DropdownButton = ({
  items,
  onSelect,
  children
}: DropdownButtonProps) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          iconPosition="end"
          size="sm"
          iconType="chevronDown"
          variant="outline"
          className="hover:bg-white"
        >
          {children}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="center" className="w-fit">
        {items.map(({ label, key }) => (
          <DropdownMenuItem
            key={key}
            onSelect={() => onSelect(key)}
            className="cursor-pointer"
          >
            {label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
