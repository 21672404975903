import isArray from "lodash/isArray"

import { toast } from "components/ui/use-toast"
import translations from "translations"
import { ResultModel } from "types/ApiResponse"
import { ServerError } from "types/Error"

export const hasServerError = <T>(err: any): err is ResultModel<T> =>
  err?.errors && isArray(err.errors) && err.errors.length > 0

export const getServerErrors = <T>(err?: ResultModel<T>) => {
  if (!hasServerError(err)) {
    return []
  }

  return err.errors
    .map((errorCode) => {
      const errorMessage = translations.t(`ApiErrors.${errorCode}`)
      return errorMessage !== errorCode && errorMessage
    })
    .filter(Boolean) as string[]
}

export const showErrorNotification = <T>(
  err?: ResultModel<T> | unknown,
  defaultMessage?: string
) => {
  if (hasServerError(err)) {
    return err.errors.map((errorCode) => {
      const errorMessage = translations.t(`ApiErrors.${errorCode}`)
      toast({
        title: "Wystąpił błąd",
        description: errorMessage !== errorCode ? errorMessage : defaultMessage,
        variant: "destructive"
      })
    })
  }

  return toast({
    title: "Wystąpił błąd",
    description: defaultMessage,
    variant: "destructive"
  })
}

export const mapServerErrors =
  <T extends string, K extends Record<string, unknown>>(
    fieldMap: Record<T, keyof K>
  ) =>
  (res?: ResultModel<T[]>) => {
    let serverErrors: ServerError<K> = {}
    const fieldError = getErrorField(fieldMap)

    if (!hasServerError(res)) {
      return serverErrors
    }

    res.errors.forEach((err) => {
      const error = fieldError(err)
      if (error) {
        serverErrors = {
          ...serverErrors,
          [error[0]]: error[1]
        }
      }
    })

    return serverErrors
  }

const getErrorField =
  <K extends Record<string, unknown>>(fieldMap: Record<string, keyof K>) =>
  (err: string): [keyof K, string] | null => {
    if (typeof err !== "string") {
      return null
    }

    if (!fieldMap[err]) {
      return null
    }

    return [fieldMap[err], translations.t(`ApiErrors.${err}`)]
  }

export const hasConcreteError = <T>(err: ResultModel<T>, errorCode: string) => {
  if (!hasServerError(err)) {
    return false
  }

  if (!err.errors.includes(errorCode)) {
    return false
  }

  return true
}
