import {
  ColumnDef,
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table"

type TableState<T> = {
  data: T[]
  columns: ColumnDef<T>[]
}

export function useTable<T>({ data, columns }: TableState<T>) {
  const table = useReactTable({
    data,
    columns,
    defaultColumn: {
      enableResizing: false
    },
    getCoreRowModel: getCoreRowModel()
  })

  return {
    table
  }
}
