import React from "react"
import { ReactourStep } from "reactour"

import { Paragraph } from "components/Text"
import { TransFunc } from "utils/usePrefix"

import { SimpleStep } from "../components/SimpleStep"

export const testMainPageSteps = (t: TransFunc): ReactourStep[] => [
  {
    selector: "[data-tour='main-page-step-nav']",
    stepInteraction: false,
    content: ({ goTo }) => {
      return (
        <SimpleStep
          content={
            <>
              <Paragraph>{t("test_main_page_step_1_line_1")}</Paragraph>
              <Paragraph>{t("test_main_page_step_1_line_2")}</Paragraph>
              <Paragraph>{t("test_main_page_step_1_line_3")}</Paragraph>
            </>
          }
          onNextStep={() => goTo(1)}
        />
      )
    }
  },
  {
    selector: "[data-tour='main-page-step-content']",
    stepInteraction: false,
    content: ({ goTo }) => {
      return (
        <SimpleStep
          content={
            <>
              <Paragraph>{t("test_main_page_step_2_line_1")}</Paragraph>
              <Paragraph>{t("test_main_page_step_2_line_2")}</Paragraph>
            </>
          }
          onPrevStep={() => goTo(0)}
          onNextStep={() => goTo(2)}
        />
      )
    }
  },
  {
    selector: "[data-tour='main-page-step-bulletin-cta']",
    action: (node) => {
      node.focus()
    },
    content: ({ goTo }) => {
      return (
        <SimpleStep
          content={
            <>
              <Paragraph>{t("test_main_page_step_3_line_1")}</Paragraph>
              <Paragraph>{t("test_main_page_step_3_line_2")}</Paragraph>
              <Paragraph className="font-medium text-foreground">
                {t("test_main_page_step_3_line_3")}
              </Paragraph>
            </>
          }
          onPrevStep={() => goTo(1)}
        />
      )
    }
  }
]
