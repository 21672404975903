import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"

import SessionManager from "api/SessionManager"
import { AuthorAvatar } from "components/AuthorAvatar"
import { Button } from "components/Button"
import { CustomerServiceSection } from "components/CustomerServiceSection"
import Icon from "components/Icons"
import { NotificationsModal } from "components/Modal"
import { Separator } from "components/ui/separator"
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle
} from "components/ui/sheet"
import { Realm } from "types/Session"
import usePrefix from "utils/usePrefix"

interface AccountDrawerProps {
  isOpen: boolean
  onClose: () => void
}

export const AccountDrawer: React.FC<AccountDrawerProps> = ({
  isOpen,
  onClose
}) => {
  const t = usePrefix("AccountDrawer")
  const navigate = useNavigate()

  const [isNotificationModal, setNotificationModal] = useState(false)

  const userFullName = SessionManager.getUserFullName()
  const userEmail = SessionManager.getUserEmail()
  const isAOPublisher = SessionManager.getIsAOPublisher()
  const isAORole = SessionManager.getAORole()
  const realm = SessionManager.getRealm()

  const logoutBorg = () => {
    onClose()
    SessionManager.logout()
  }

  const redirectToCreator = () => {
    navigate("/creator/contact")
    onClose()
  }

  const redirectToLeadForm = () => {
    navigate("/config/leadregistration")
    onClose()
  }

  // TODO  We give up functionality, but at the client request we do not remove the code so that it can be restored in the future -> Jira task - ADT-1268
  // const openTour = () => {
  //   dispatch(setTourType(TourEnum.MAIN_PAGE))
  //   onClose()
  //   navigate("/")
  // }

  const openNotificationModal = () => {
    onClose()
    setNotificationModal(true)
  }

  const onCloseModal = () => {
    setNotificationModal(false)
  }

  return (
    <>
      <Sheet open={isOpen} onOpenChange={onClose}>
        <SheetContent className="flex h-full flex-col overflow-y-auto">
          <SheetHeader>
            <SheetTitle
              style={{
                letterSpacing: "2px",
                marginTop: 12,
                marginBottom: 16
              }}
            >
              {t("account_header")}
            </SheetTitle>
            <div className="flex">
              <div className="mr-2">
                <AuthorAvatar fullName={userFullName} large />
              </div>
              <div>
                <div className="flex items-center justify-between">
                  <p className="mb-0 text-base">{userFullName}</p>

                  <Icon
                    type="checkCircle"
                    width="16px"
                    height="16px"
                    className="ml-1 stroke-primary text-primary"
                  />
                </div>

                <p className="text-xs text-secondary-foreground">{userEmail}</p>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={logoutBorg}
                  className="mt-2"
                >
                  {t("logout")}
                </Button>
              </div>
            </div>
          </SheetHeader>
          <Separator />
          <div className="-ml-4 flex flex-col">
            {isAOPublisher && (
              <div className="mb-1">
                <Button
                  iconType="settings"
                  variant="navbarLink"
                  onClick={redirectToCreator}
                >
                  {t("settings")}
                </Button>
              </div>
            )}
            {isAORole && (
              <div className="mb-1">
                <Button
                  iconType="notificationOn"
                  variant="navbarLink"
                  onClick={openNotificationModal}
                >
                  {t("notifications")}
                </Button>
              </div>
            )}

            {isAOPublisher && (
              <div className="mb-1">
                <Button
                  iconType="users"
                  variant="navbarLink"
                  onClick={redirectToLeadForm}
                >
                  {t("leadForm_config")}
                </Button>
              </div>
            )}

            {isAORole && (
              // <div $mb="4px">
              //   <Button iconType="infoRound" onClick={openTour} >
              //     {t("tutorial")}
              //   </Button>
              // </Box>

              <div className="mb-1 [&>a]:text-foreground">
                <Link to={faqLinkMap[realm]} target="_blank">
                  <Button
                    iconType="question"
                    variant="navbarLink"
                    data-gtm="gtm-help-centre"
                  >
                    {t("faq")}
                  </Button>
                </Link>
              </div>
            )}
          </div>
          <CustomerServiceSection />
        </SheetContent>
      </Sheet>
      {isNotificationModal && <NotificationsModal onClose={onCloseModal} />}
    </>
  )
}

const faqLinkMap: Record<Realm, string> = {
  Accountancy: "https://pomoc.wolterskluwer.pl/tax-alert/",
  Law: "https://pomoc.wolterskluwer.pl/legal-alert/",
  Public: "https://pomoc.wolterskluwer.pl/alert-urzedowy/"
}
