import { AditionalMultiStepProps } from "./MulitStepState"

export const setCurrentStep = (step: number) =>
  ({
    type: "multiStep/SET_CURRENT_STEP",
    step
  }) as const

export const setPrevStep = () =>
  ({
    type: "multiStep/SET_PREV_STEP"
  }) as const

export const initMultiStep = (stepNames: string[]) =>
  ({
    type: "multiStep/INIT_MULTI_STEP",
    stepNames
  }) as const

export const setMultiStepFormValues = (values: any) =>
  ({
    type: "multiStep/SET_MULTI_STEP_FORM_VALUES",
    values
  }) as const

export const setStepInEditMode = (
  step: number,
  additionalProps: AditionalMultiStepProps | null = null
) =>
  ({
    type: "multiStep/SET_STEP_IN_EDIT_MODE",
    step,
    additionalProps
  }) as const

export const clearMultiStepData = () =>
  ({
    type: "multiStep/CLEAR_MULTI_STEP_DATA"
  }) as const

export type MulitStepAction = ReturnType<
  | typeof setCurrentStep
  | typeof setPrevStep
  | typeof initMultiStep
  | typeof setMultiStepFormValues
  | typeof setStepInEditMode
  | typeof clearMultiStepData
>
