import { useField } from "formik"
import React from "react"

import { Label } from "components/ui/label"

export interface FormFieldWrapperProps {
  name: string
  label?: React.ReactNode
  required?: boolean
  charLimit?: string
  withMask?: boolean
  children: React.ReactNode
}

const FormFieldWrapper = ({
  name,
  label,
  required = false,
  charLimit,
  withMask,
  children
}: FormFieldWrapperProps) => {
  const [, meta] = useField(name)
  const { touched, error } = meta

  return (
    <div className="flex flex-col gap-y-1">
      {label && (
        <Label htmlFor={name} className="mb-1 flex justify-between">
          <span>
            {required && <span className="mr-1 text-destructive">*</span>}
            {label}
          </span>
          {charLimit && (
            <span className="font-normal text-muted-foreground">
              {charLimit}
            </span>
          )}
        </Label>
      )}
      {children}
      {!withMask && touched && error && (
        <p className="text-xs text-destructive">{error}</p>
      )}
    </div>
  )
}

export default FormFieldWrapper
