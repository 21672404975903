import { createSelector } from "reselect"

import { ClientStatusEnum } from "enums/client"
import { NormalizedModel } from "types/ApiResponse"
import { Client } from "types/Client"

import { AppState } from "../AppState"

export const selectClientPending = (s: AppState) => s.client.isFetching

export const selectClientPagination = (s: AppState) => s.client.pagination

export const selectClientListData = (s: AppState) => s.client.data

export const selectClientList = createSelector(
  selectClientListData,
  (clientList) => clientList || []
)

export const selectClientById = (s: AppState, id?: string) =>
  s.client.data?.find((client) => client.id === id)

export const selectActiveClientList = createSelector(
  selectClientList,
  (clientList) =>
    clientList.filter(({ status }) => status === ClientStatusEnum.Active)
)

export const selectClientsGroupedByLabel = createSelector(
  selectClientList,
  (clients) => clients.reduce(normalizeByTags, {})
)

export const selectClientsGroupedByActivityForm = createSelector(
  selectClientList,
  (clients) => clients.reduce(normalizeByActivityForm, {})
)

export const selectClientsGroupedByCustomTags = createSelector(
  selectClientList,
  (clients) => clients.reduce(normalizeByCustomTags, {})
)

const normalizeByTags = (result: NormalizedModel<Client[]>, value: Client) => {
  value.tags?.map((labelId) => {
    result[labelId] = result[labelId] || []
    result[labelId].push(value)
  })
  return result
}

const normalizeByCustomTags = (
  result: NormalizedModel<Client[]>,
  value: Client
) => {
  value.customTags?.map((labelId) => {
    result[labelId] = result[labelId] || []
    result[labelId].push(value)
  })
  return result
}

const normalizeByActivityForm = (
  result: NormalizedModel<Client[]>,
  value: Client
) => {
  if (value.activityFormCode) {
    result[value.activityFormCode] = result[value.activityFormCode] || []
    result[value.activityFormCode].push(value)
  }
  return result
}
