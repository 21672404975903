import React from "react"

import { Button } from "components/Button"
import { Header } from "components/Text"
import { useCopyToClipboard } from "utils/hooks"
import usePrefix from "utils/usePrefix"

export const CustomerServiceSection = () => {
  const t = usePrefix("CustomerService")

  const copy = useCopyToClipboard()

  return (
    <div className="flex h-full flex-col justify-end">
      <div>
        <Header className="mb-2 mt-3 tracking-[2px] text-foreground">
          {t("customer_service_header")}/{t("service_desk_header")}
        </Header>
        <div className="pb-2 text-xs text-accent-foreground">
          {t("service_desk_description")}
        </div>
      </div>
      <div>
        <div className="-ml-4 flex flex-col-reverse items-start justify-between">
          <Button
            iconType="mail"
            onClick={() => copy(t("email_service_desk"))}
            variant="navbarLink"
          >
            {t("email_service_desk")}
          </Button>

          <Button
            iconType="mail"
            onClick={() => copy(t("email_customer_service"))}
            variant="navbarLink"
          >
            {t("email_customer_service")}
          </Button>

          <Button
            iconType="call"
            onClick={() =>
              copy(`${t("contact_phone1")}, ${t("contact_phone2")}`)
            }
            variant="navbarLink"
          >
            {t("contact_phone1")}, {t("contact_phone2")}
          </Button>
        </div>
      </div>
    </div>
  )
}
