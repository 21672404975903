import { FormikProps } from "formik"
import React, { useCallback, useState } from "react"

import { BasicConfig } from "components/Modal/AutoNewsletterDelivery/Sections/BasicConfig"
import { IgnoreNewsConfig } from "components/Modal/AutoNewsletterDelivery/Sections/IgnoreNewsConfig"
import { MonthlyConfig } from "components/Modal/AutoNewsletterDelivery/Sections/MonthlyConfig"
import { WeeklyConfig } from "components/Modal/AutoNewsletterDelivery/Sections/WeeklyConfig"
import { Text } from "components/Text"
import { DialogComponent } from "components/ui/dialog"
import { AutoNewsletterDeliveryValues } from "types/AutoNewsletterDelivery"
import { showErrorNotification } from "utils/errors"
import usePrefix from "utils/usePrefix"

export type FieldValueUpdate = (
  field: keyof AutoNewsletterDeliveryValues,
  value: unknown
) => void

interface AutoNewsletterDeliveryModalProps {
  onClose: () => void
  isOpen: boolean
  formikProps: FormikProps<AutoNewsletterDeliveryValues>
}

export const AutoNewsletterDeliveryModal = ({
  isOpen,
  onClose,
  formikProps
}: AutoNewsletterDeliveryModalProps) => {
  const t = usePrefix("AutoDelivery")

  const [isLoading, setLoading] = useState(false)
  const { values, setFieldValue, submitForm } = formikProps
  const { dayOfWeek, time, cycle } = values

  const handleSave = useCallback(async () => {
    try {
      setLoading(true)
      await submitForm()
    } catch (err) {
      showErrorNotification(err)
    } finally {
      setLoading(false)
      onClose()
    }
  }, [onClose, submitForm])

  const handleChange: FieldValueUpdate = useCallback(
    (field, value) => setFieldValue(field, value),
    [setFieldValue]
  )

  return (
    <DialogComponent
      open={isOpen}
      onOpenChange={onClose}
      onCancel={onClose}
      onConfirm={handleSave}
      title={t("modalTitle")}
      description={
        <div className="my-3 rounded-s border border-warning-foreground p-4">
          <Text>{t("autoDeliveryWarning")}</Text>
        </div>
      }
      isLoading={isLoading}
      canClose={false}
      datagtm="auto-mailing-turnon-save"
      className="flex w-[750px] flex-col"
    >
      <BasicConfig cycle={cycle} time={time} onChange={handleChange} />
      <Text className="pt-6">{t("delivery")}</Text>
      <div
        style={{
          height: `${cycle === "Monthly" ? "130px" : "70px"}`,
          transition: "height 0.3s ease"
        }}
      >
        {cycle === "Monthly" ? (
          <MonthlyConfig values={values} onChange={handleChange} />
        ) : (
          <WeeklyConfig dayOfWeek={dayOfWeek} onChange={handleChange} />
        )}
      </div>

      <IgnoreNewsConfig values={values} onChange={handleChange} />
    </DialogComponent>
  )
}
