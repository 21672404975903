import React from "react"

import { Button } from "components/Button"
import usePrefix from "utils/usePrefix"

interface SimpleStepProps {
  content: React.ReactNode
  prevStepLabel?: string
  nextStepLabel?: string
  onPrevStep?: () => void
  onNextStep?: () => void
}

export const SimpleStep: React.FC<SimpleStepProps> = ({
  content,
  prevStepLabel,
  nextStepLabel,
  onPrevStep,
  onNextStep
}) => {
  const t = usePrefix("TestTour")

  return (
    <>
      {content}

      <div className="flex justify-end">
        <div>
          <div className="flex">
            {!!onPrevStep && (
              <Button variant="outline" onClick={onPrevStep}>
                {prevStepLabel || t("back")}
              </Button>
            )}
            {!!onNextStep && (
              <Button variant="default" onClick={onNextStep}>
                {nextStepLabel || t("next")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
