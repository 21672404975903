import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, matchPath, useLocation, useNavigate } from "react-router-dom"

import { Button } from "components/Button"
import Icon, { IconName } from "components/Icons"
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList
} from "components/ui/navigation-menu"
import { selectNavBarStatus } from "store/layout"
import { toggleNavBar } from "store/layout/layoutActions"

interface AppNavigationProps {
  routes: {
    label: string
    route: string
    iconType: IconName
    datagtm: string
    datatour?: string
  }[]
  ctaBtn?: {
    label: string
    route: string
    iconType?: IconName
    datagtm: string
    datatour?: string
    onClick?: (e: any) => void
    disabled?: boolean
  }
}

const withoutNavRoutes = [
  "/account",
  "/creator",
  "/news/add",
  "/news/edit",
  "/newsletter/add",
  "/notifications"
]

export const AppNavigation = ({ routes, ctaBtn }: AppNavigationProps) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const withNavBar = useSelector(selectNavBarStatus)
  const [selectedKey, setMenuKey] = useState<string>("")

  useEffect(() => {
    !withoutNavRoutes.some((route) => !!matchPath({ path: pathname }, route))
      ? dispatch(toggleNavBar(true))
      : dispatch(toggleNavBar(false))
  }, [dispatch, pathname])

  const currentSelected =
    !!selectedKey.length &&
    !!matchPath({ path: location.pathname }, selectedKey)

  useEffect(() => {
    if (!currentSelected) {
      let navRouteFound = false
      routes.forEach(({ route }) => {
        if (matchPath({ path: pathname }, route)) {
          setMenuKey(route)
          navRouteFound = true
        }
      })
      !navRouteFound && setMenuKey("")
    }
  }, [currentSelected, pathname, routes])

  if (!withNavBar) {
    return null
  }

  return (
    <div className="w-full border-b border-input/50 pt-2">
      <div className="container mx-auto flex items-center justify-between px-4">
        <NavigationMenu>
          <NavigationMenuList className="flex">
            {routes.map(({ route, label, datagtm, iconType }) => (
              <NavigationMenuItem
                key={route}
                datagtm={datagtm}
                onClick={() => {
                  setMenuKey(route)
                  navigate(route)
                }}
              >
                <NavigationMenuLink
                  href={route}
                  className={`relative flex cursor-pointer flex-col items-center gap-2 text-nowrap bg-inherit p-2 px-4 text-center text-lg font-normal transition-all duration-100 hover:stroke-primary hover:text-primary sm:w-20 md:w-28 lg:w-40 ${
                    route === selectedKey
                      ? "stroke-primary text-primary"
                      : "stroke-secondary-foreground text-secondary-foreground"
                  } after:absolute after:bottom-0 after:m-auto after:h-0 after:rounded-t-[3px] after:bg-primary after:transition-all after:duration-300 ${
                    route === selectedKey
                      ? "after:h-[3px] after:w-full"
                      : "after:h-0 after:w-0"
                  } hover:after:h-[3px] hover:after:w-full`}
                >
                  <Icon
                    type={iconType}
                    className="h-6 w-6 stroke-current stroke-[1.2]"
                  />
                  <span className="max-lg:hidden">{label}</span>
                </NavigationMenuLink>
              </NavigationMenuItem>
            ))}
          </NavigationMenuList>
        </NavigationMenu>
        {ctaBtn ? (
          <Link to={ctaBtn.route} onClick={ctaBtn.onClick}>
            <Button
              variant="alternative"
              datagtm={ctaBtn.datagtm}
              data-tour={ctaBtn.datatour}
              disabled={ctaBtn.disabled}
              className="h-8 w-12 lg:h-10 lg:w-fit"
            >
              {ctaBtn.iconType && (
                <Icon
                  type={ctaBtn.iconType}
                  className="h-6 w-6 stroke-white stroke-[1.2] lg:hidden"
                />
              )}
              <span className="max-lg:hidden">{ctaBtn.label}</span>
            </Button>
          </Link>
        ) : null}
      </div>
    </div>
  )
}
