import { useField } from "formik"
import React, { useCallback } from "react"

import { Label } from "components/ui/label"
import { RadioGroup, RadioGroupItem } from "components/ui/radio-group"
import { cn } from "lib/utils"

import FormFieldWrapper from "./FormFieldWrapper"

interface RadioOption {
  value: string
  label: React.ReactNode
}

interface FormRadioGroupProps {
  name: string
  label?: React.ReactNode
  options: RadioOption[]
  onChange?: (value: string) => void
  required?: boolean
  disabled?: boolean
  className?: string
}

const FormRadioGroup: React.FC<FormRadioGroupProps> = ({
  name,
  label,
  options,
  onChange,
  required = false,
  disabled = false,
  className
}) => {
  const [field, meta, helpers] = useField(name)
  const { error } = meta

  const handleChange = useCallback(
    (value: string) => {
      helpers.setValue(value)
      if (onChange) onChange(value)
    },
    [helpers, onChange]
  )

  return (
    <FormFieldWrapper name={name} label={label} required={required}>
      <RadioGroup
        value={String(field.value)}
        onValueChange={handleChange}
        className={cn(
          `flex flex-row ${
            error ? "border-destructive focus-visible:ring-destructive/50" : ""
          }`,
          className
        )}
      >
        {options.map((option, index) => (
          <div key={index} className="flex items-center space-x-2">
            <RadioGroupItem
              value={option.value}
              id={`${name}-${option.value}`}
              disabled={disabled}
            />
            <Label
              htmlFor={`${name}-${option.value}`}
              className="cursor-pointer font-normal"
            >
              {option.label}
            </Label>
          </div>
        ))}
      </RadioGroup>
      {error && <div className="mt-1 text-sm text-red-500">{error}</div>}
    </FormFieldWrapper>
  )
}

export default FormRadioGroup
