import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { selectClientCount, selectClientLimit } from "store/layout"
import * as LocalStorage from "store/storage/localStorage"

export const useExceededClientsLimit = () => {
  const clientCount = useSelector(selectClientCount)
  const clientLimit = useSelector(selectClientLimit)

  const [approachingClientLimitExceeded, setApproachingClientLimitExceeded] =
    useState<boolean | undefined>(undefined)
  const [achievedClientsLimit, setAchievedClientsLimit] = useState<
    boolean | undefined
  >(undefined)
  const [exceededClientsLimit, setExceededClientsLimit] = useState<
    boolean | undefined
  >(undefined)

  const loadIgnoreLimit = !!LocalStorage.loadLocalState(
    LocalStorage.IGNORE_EXCEEDED_LIMIT
  )
  const setIgnoreLimit = (value: boolean) =>
    LocalStorage.saveLocalState(LocalStorage.IGNORE_EXCEEDED_LIMIT, value)
  const removeIgnoreLimit = () =>
    LocalStorage.removeLocalState(LocalStorage.IGNORE_EXCEEDED_LIMIT)

  useEffect(() => {
    if (clientLimit > 0) {
      setApproachingClientLimitExceeded(clientCount > clientLimit * 0.79)
      setAchievedClientsLimit(clientCount >= clientLimit)
      setExceededClientsLimit(clientCount > clientLimit)
    } else {
      setApproachingClientLimitExceeded(undefined)
      setAchievedClientsLimit(undefined)
      setExceededClientsLimit(undefined)
    }
  }, [clientCount, clientLimit])

  useEffect(() => {
    if (clientCount < clientLimit * 0.79 && loadIgnoreLimit) {
      removeIgnoreLimit()
    }
  }, [clientCount, clientLimit, loadIgnoreLimit])

  return {
    clientCount,
    clientLimit,
    approachingClientLimitExceeded,
    achievedClientsLimit,
    exceededClientsLimit,
    loadIgnoreLimit,
    setIgnoreLimit,
    removeIgnoreLimit
  }
}
