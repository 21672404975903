import React, { useState } from "react"

import { TagList } from "components/Tag"
import { Checkbox } from "components/ui/checkbox"
import { DialogComponent } from "components/ui/dialog"
import { ClientGUS } from "types/Client"
import usePrefix from "utils/usePrefix"

export interface MultipleClientNIPModalProps {
  isVisible: boolean
  clients: ClientGUS[]
  onClose: () => void
  onFillData: (client: ClientGUS) => void
}

export const MultipleClientNIPModal: React.FC<MultipleClientNIPModalProps> = ({
  isVisible,
  clients,
  onClose,
  onFillData
}) => {
  const t = usePrefix("MultipleNIP")
  const [selectedClient, onSelectClient] = useState<number | null>(null)

  const onFillGusData = () => {
    if (selectedClient !== null) {
      onFillData(clients[selectedClient])
    }
  }

  return (
    <DialogComponent
      open={isVisible}
      onOpenChange={onClose}
      okText={t("fillData")}
      onConfirm={onFillGusData}
      confirmDisabled={selectedClient === null}
    >
      <div className="pb-6">
        <h2>{t("modalTitle")}</h2>
      </div>
      <div className="mb-14 mt-2 max-h-[50vh] overflow-y-auto overflow-x-hidden last:border-b [&>div]:border-t [&>div]:border-border">
        {clients.map((client, index) => (
          <ClientRow
            key={index}
            index={index}
            onSelectClient={onSelectClient}
            selectedClient={selectedClient}
            {...client}
          />
        ))}
      </div>
    </DialogComponent>
  )
}

interface ClientRowProps {
  index: number
  selectedClient: number | null
  onSelectClient: (index: number) => void
}

const ClientRow: React.FC<ClientGUS & ClientRowProps> = ({
  index,
  companyName,
  fullName,
  nip,
  tags,
  customTags,
  activityFormCode,
  pkdCode,
  onSelectClient,
  selectedClient
}) => {
  return (
    <div className="px-4 py-6 text-secondary-foreground hover:shadow xl:px-20">
      <div className="flex items-center">
        <div>
          <Checkbox
            checked={selectedClient === index}
            onCheckedChange={() => onSelectClient(index)}
          />
        </div>
        <div className="flex w-full gap-2 pl-4">
          <div className="flex-1">
            <h4 className="m-0 truncate">{companyName}</h4>
            <h5 className="text-secondary-foreground">{nip}</h5>
          </div>
          <div style={{ whiteSpace: "normal", flex: 1 }}>{fullName}</div>
          <div style={{ whiteSpace: "normal", flex: 1 }}>
            <TagList
              tags={tags}
              customTags={customTags}
              activityFormCode={activityFormCode}
              pkdCode={pkdCode}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
