import React from "react"

export const useRenderTooltip = (tooltips: string[]) => {
  const filteredTooltips = tooltips.filter((tooltip) => tooltip?.length)

  return (
    <div className="flex flex-col gap-2">
      {filteredTooltips.map((tooltip, index) => (
        <div key={index}>{tooltip}</div>
      ))}
    </div>
  )
}
