import * as React from "react"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { AlertBar } from "components/ui/alert"
import { fetchAutoNewsletterDelivery } from "store/autoNewsletterDelivery"
import { useDeliveryDateInformation } from "utils/newsletterDelivery/getDeliveryDetails"
import usePrefix from "utils/usePrefix"

export const AutoNewsletterDeliveryAlert = () => {
  const tNews = usePrefix("News")
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAutoNewsletterDelivery())
  }, [dispatch])

  const { minutesDifference, formattedTime } = useDeliveryDateInformation()

  const [isDeliveryWarningVisible, setIsDeliveryWarningVisible] = useState(
    !!formattedTime && !!minutesDifference && minutesDifference < 120
  )

  return (
    isDeliveryWarningVisible && (
      <AlertBar
        message={tNews("auto_delivery_warning", {
          time: formattedTime
        })}
        variant="warning"
        onClose={() => setIsDeliveryWarningVisible(false)}
      />
    )
  )
}
