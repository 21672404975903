import React from "react"

import Icon from "components/Icons"
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport
} from "components/ui/toast"
import { useToast } from "components/ui/use-toast"

export function Toaster() {
  const { toasts } = useToast()

  return (
    <ToastProvider>
      {toasts.map(function ({
        id,
        title,
        description,
        action,
        variant,
        ...props
      }) {
        return (
          <Toast key={id} {...props}>
            <div className="grid gap-1">
              {title && (
                <ToastTitle className="flex items-center gap-3">
                  {variant === "destructive" ? (
                    <Icon
                      type="xCircle"
                      className="h-6 w-6 stroke-destructive stroke-2"
                    />
                  ) : (
                    <Icon
                      type="checkCircle"
                      className="h-6 w-6 stroke-alternative stroke-2"
                    />
                  )}
                  {title}
                </ToastTitle>
              )}
              {description && (
                <ToastDescription>{description}</ToastDescription>
              )}
            </div>
            {action}
            <ToastClose />
          </Toast>
        )
      })}
      <ToastViewport />
    </ToastProvider>
  )
}
