import { Loader2 } from "lucide-react"
import React, { PropsWithChildren } from "react"

import Icon, { IconName } from "components/Icons"
import { Button as ShadcnButton, ButtonProps } from "components/ui/button"

export type MainButtonProps = ButtonProps &
  PropsWithChildren & {
    datagtm?: string
    iconType?: IconName
    iconPosition?: "start" | "end"
    loading?: boolean
  }

export const Button = React.forwardRef<HTMLButtonElement, MainButtonProps>(
  (props, ref) => {
    const {
      children,
      iconType,
      loading,
      iconPosition = "start",
      type = "button",
      ...rest
    } = props

    const iconColor = `${rest.variant === "navbarLink" ? "stroke-primary" : "stroke-current"}`

    const renderIcon = iconType ? (
      <Icon type={iconType} className={`${iconColor}`} />
    ) : null

    return (
      <ShadcnButton ref={ref} type={type} {...rest}>
        {loading && <Loader2 className="h-4 w-4 animate-spin" />}
        {iconPosition === "start" && renderIcon}
        {children}
        {iconPosition === "end" && renderIcon}
      </ShadcnButton>
    )
  }
)

Button.displayName = "Button"
