import { FormikProps } from "formik"
import React from "react"

import { Button } from "components/Button"
import FormTextInput from "components/Form/FormTextInput"
import { PasswordPromptFormValues } from "components/Modal/PasswordPrompt/form"
import { DialogComponent } from "components/ui/dialog"
import { TooltipComponent } from "components/ui/tooltip"
import usePrefix from "utils/usePrefix"
import { useRenderTooltip } from "utils/useRenderTooltip"

export interface PasswordPromptFormProps {
  isVisible: boolean
  onClose: () => void
  formikProps: FormikProps<PasswordPromptFormValues>
}

export const PasswordPromptForm: React.FC<PasswordPromptFormProps> = ({
  isVisible,
  onClose,
  formikProps
}) => {
  const tBulletin = usePrefix("Bulletin")
  const tCreator = usePrefix("Creator")

  const passModalTooltip = tBulletin("passModalTooltip")
  const passModalTooltipCont = tBulletin("passModalTooltipCont")
  const tooltip = useRenderTooltip([passModalTooltip, passModalTooltipCont])

  return (
    <DialogComponent
      open={isVisible}
      onOpenChange={onClose}
      title={tBulletin("passModalTitle")}
      description={tBulletin("passModalDescription")}
      className="w-[660px] overflow-auto"
      hideFooter
    >
      <div className="flex flex-col">
        <div className="mb-4">
          <FormTextInput
            name="fromAddress"
            label={tCreator("emailLogin")}
            type="text"
            disabled
          />
        </div>
        <div className="mb-1">
          <FormTextInput
            name="smtpPassword"
            label={tBulletin("passModalLabel")}
            type="password"
            required
          />
        </div>

        <TooltipComponent
          align="end"
          tooltipContent={tooltip}
          triggerContent={
            <div className="flex flex-row-reverse text-sm font-medium text-primary hover:cursor-help">
              {tBulletin("passModalTip")}
            </div>
          }
        />

        <div className="mt-14 flex justify-between">
          <Button
            onClick={onClose}
            size="lg"
            variant="outline"
            style={{ width: "130px" }}
          >
            {tBulletin("cancel")}
          </Button>

          <Button
            style={{ width: "200px" }}
            variant="default"
            size="lg"
            type="submit"
            disabled={!formikProps.isValid}
            onClick={formikProps.submitForm}
          >
            {tBulletin("send")}
          </Button>
        </div>
      </div>
    </DialogComponent>
  )
}
