import React, { useEffect, useState } from "react"

import { AlertBar, AlertBarProps } from "components/ui/alert"
import { useCopyToClipboard } from "utils/hooks"
import { useExceededClientsLimit } from "utils/useExceededClientsLimitData"
import usePrefix from "utils/usePrefix"

export const TopAlertBar = () => {
  const tClients = usePrefix("Clients")
  const copy = useCopyToClipboard()

  const [isWarningVisible, setIsWarningVisible] = useState(false)
  const {
    achievedClientsLimit,
    approachingClientLimitExceeded,
    loadIgnoreLimit,
    setIgnoreLimit
  } = useExceededClientsLimit()

  useEffect(() => {
    const isVisible =
      (achievedClientsLimit || approachingClientLimitExceeded) &&
      !loadIgnoreLimit
    setIsWarningVisible(!!isVisible)
  }, [
    achievedClientsLimit,
    approachingClientLimitExceeded,
    setIsWarningVisible,
    loadIgnoreLimit
  ])

  const copyContent = (
    <span
      onClick={() => copy(tClients("clientsLimitExceededInfoEmail"))}
      className="hover:text-current/90 h-5 cursor-pointer text-current hover:underline"
    >
      {tClients("clientsLimitExceededInfoEmail")}
    </span>
  )

  const getWarningMessage = () => {
    if (achievedClientsLimit) {
      return (
        <div>
          {`${tClients("clientsLimitExceeded")} ${tClients("clientsLimitExceededInfo")} `}
          {copyContent}
          {")"}
        </div>
      )
    }
    return (
      <div>
        {`${tClients("approachingClientsLimitExceeded")} ${tClients("approachingClientsLimitExceededInfo")} `}
        {copyContent}
        {")"}
      </div>
    )
  }

  const getWarningVariant = (): AlertBarProps["variant"] => {
    return achievedClientsLimit ? "destructive" : "warning"
  }

  return (
    isWarningVisible && (
      <AlertBar
        message={getWarningMessage()}
        variant={getWarningVariant()}
        onClose={() => {
          setIsWarningVisible(false)
          setIgnoreLimit(true)
        }}
      />
    )
  )
}
