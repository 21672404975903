import React from "react"

import Icon from "components/Icons"
import { Toggle } from "components/ui/toggle"
import { TooltipComponent } from "components/ui/tooltip"
import { cn } from "lib/utils"
import { useRenderTooltip } from "utils/useRenderTooltip"

interface TagProps {
  checked: boolean
  children: React.ReactNode
  tooltip?: string
  isIndeterminate?: boolean
  formType: "client" | "news"
  onChange?: (isChecked: boolean, isIndeterminate: boolean) => void
  onClose?: () => void
  tip?: string
  className?: string
}

export const Tag = ({
  checked,
  children,
  tooltip,
  isIndeterminate = false,
  tip,
  formType,
  onChange,
  onClose,
  className
}: TagProps) => {
  const { finalTooltip } = useTag({
    checked,
    children,
    tooltip,
    isIndeterminate,
    tip
  })

  const handleChangeState = (isChecked: boolean) => {
    if (formType === "news") {
      onChange?.(!isIndeterminate && isChecked, !isChecked)
    } else {
      onChange?.(isChecked, false)
    }
  }

  return (
    <TooltipComponent
      triggerContent={
        <div className="relative inline-flex items-center">
          <Toggle
            variant="outline"
            size="sm"
            pressed={checked}
            onPressedChange={handleChangeState}
            className={cn(
              `${checked && "hover:text-primary"} ${
                isIndeterminate &&
                "border-alternative text-alternative hover:text-alternative"
              } ${!!onClose && "pr-6"}`,
              className
            )}
          >
            <span className="truncate">{children}</span>
          </Toggle>
          {!!onClose && (
            <div
              className="absolute right-[8px] top-[10px] cursor-pointer"
              onClick={onClose}
            >
              <Icon type="close" className="h-[14px] w-[14px]" />
            </div>
          )}
        </div>
      }
      tooltipContent={finalTooltip}
    />
  )
}

export const useTag = ({
  checked,
  isIndeterminate,
  tooltip,
  tip,
  children
}: Pick<
  TagProps,
  "tooltip" | "children" | "checked" | "isIndeterminate" | "tip"
>) => {
  const titleTooltipPrefix = checked
    ? "Wymagany - "
    : isIndeterminate
      ? "Opcjonalny - "
      : ""

  const finalTooltip = useRenderTooltip([
    `${titleTooltipPrefix}${tooltip || children}`,
    tip ? tip : ""
  ])
  return { finalTooltip }
}
