import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { MultiValue, MultiValueGenericProps } from "react-select"

import SessionManager from "api/SessionManager"
import FormFieldWrapper from "components/Form/FormFieldWrapper"
import Icon from "components/Icons"
import MultipleSelect, {
  MultipleSelectOption
} from "components/Inputs/Select/MultipleSelect"
import { TooltipComponent } from "components/ui/tooltip"
import { addCustomTag, selectCustomTagList } from "store/customTag"
import usePrefix from "utils/usePrefix"

import { Tag } from "./Tag"

interface CustomTagSelectProps {
  selectedTags: string[]
  selectedAlternative: string[]
  formType: "client" | "news"
  onChange: (customTags: string[], alternativeCustomTags?: string[]) => void
}

export const CustomTagSelect: React.FC<CustomTagSelectProps> = ({
  selectedTags,
  selectedAlternative,
  formType,
  onChange
}) => {
  const userRole = SessionManager.getUserRole()
  const dispatch = useDispatch()
  const t = usePrefix("Tags")
  const customTags = useSelector(selectCustomTagList)

  const [addedTagName, setAddedTagName] = useState("")

  const value = useMemo(() => {
    return customTags.filter(({ value }) =>
      [...selectedTags, ...selectedAlternative].includes(value)
    )
  }, [selectedTags, selectedAlternative, customTags])

  const options = useMemo(() => {
    return customTags.map(({ value, label }) => ({
      value,
      label
    }))
  }, [customTags])

  useEffect(() => {
    const fittedTagValue = customTags.find(
      (tag) => tag.label === addedTagName
    )?.value

    if (fittedTagValue) {
      const updatedTags = Array.from(new Set([...selectedTags, fittedTagValue]))
      onChange(updatedTags, selectedAlternative)
      setAddedTagName("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedTagName, customTags])

  const onTagChange = useCallback(
    (newValue: MultiValue<MultipleSelectOption>) => {
      const tags: string[] = []
      const alternativeTags: string[] = []
      newValue.forEach(({ value }) => {
        if (selectedTags.includes(value as string)) {
          tags.push(value as string)
        } else if (selectedAlternative.includes(value as string)) {
          alternativeTags.push(value as string)
        } else {
          tags.push(value as string)
        }
      })
      onChange(tags, alternativeTags)
    },
    [onChange, selectedAlternative, selectedTags]
  )

  const onAddCustomTag = useCallback(
    async (newValue: string) => {
      dispatch(addCustomTag(newValue))
      setAddedTagName(newValue)
    },
    [dispatch]
  )

  const handleChangeOptional =
    (tagValue: string) => (isChecked: boolean, isIndeterminate: boolean) => {
      if (isIndeterminate) {
        onChange(
          selectedTags.filter((tag) => tag !== tagValue),
          [...selectedAlternative, tagValue]
        )
      } else if (formType === "news") {
        onChange(
          [...selectedTags, tagValue],
          selectedAlternative.filter((tag) => tag !== tagValue)
        )
      }
    }

  const MultiValueContainer = (
    props: MultiValueGenericProps<MultipleSelectOption>
  ) => {
    const { label, value } = props.data

    const isChecked = selectedTags.some((tag) => tag === value)
    const isIndeterminate =
      formType === "news" && selectedAlternative.some((tag) => tag === value)

    if (!label) {
      return <></>
    }

    return (
      <div className="m-[2px]">
        <Tag
          checked={isChecked}
          isIndeterminate={isIndeterminate}
          formType={formType}
          onChange={handleChangeOptional(value)}
          onClose={() => {
            onChange(
              selectedTags.filter((tag) => tag !== value),
              selectedAlternative.filter((tag) => tag !== value)
            )
          }}
        >
          {label}
        </Tag>
      </div>
    )
  }

  if (userRole === "AO_User") {
    return null
  }

  return (
    <div className="w-full">
      <FormFieldWrapper
        name="customTag"
        label={
          <div className="flex items-center gap-1.5">
            {t("custom_tags_label")}
            <TooltipComponent
              tooltipContent={t("custom_tags_tooltip")}
              triggerContent={
                <span>
                  <Icon
                    type="infoRound"
                    className="h-4 w-4 stroke-secondary-foreground"
                  />
                </span>
              }
            />
          </div>
        }
      >
        <MultipleSelect
          name="customTag"
          placeholder={t("add")}
          isClearable
          components={{
            MultiValueContainer
          }}
          value={value}
          onSelectChange={onTagChange}
          options={options}
          onCreateTag={onAddCustomTag}
          allowCreate
        />
      </FormFieldWrapper>
    </div>
  )
}
