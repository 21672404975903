import React from "react"
import { ReactourStep } from "reactour"

import { Paragraph } from "components/Text"
import { TransFunc } from "utils/usePrefix"

import { SimpleStep } from "../components/SimpleStep"

export const addTestClientSteps = (t: TransFunc): ReactourStep[] => [
  {
    selector: "body",
    stepInteraction: false,
    content: ({ goTo }) => {
      return (
        <SimpleStep
          content={
            <>
              <Paragraph>{t("add_client_step_1_line_1")}</Paragraph>
              <Paragraph>{t("add_client_step_1_line_2")}</Paragraph>
              <Paragraph>{t("add_client_step_1_line_3")}</Paragraph>
            </>
          }
          onNextStep={() => goTo(1)}
        />
      )
    }
  },
  {
    selector: "[data-tour='add-client-step-1']",
    stepInteraction: true,
    action: (node) => {
      node?.querySelector("input").focus()
    },
    content: ({ goTo }) => {
      return (
        <SimpleStep
          content={
            <>
              <Paragraph>{t("add_client_step_2_line_1")}</Paragraph>
              <Paragraph>{t("add_client_step_2_line_2")}</Paragraph>
            </>
          }
          onPrevStep={() => goTo(0)}
          onNextStep={() => goTo(2)}
        />
      )
    }
  },
  {
    selector: "[data-tour='add-client-step-2']",
    action: (node) => {
      node?.querySelector("input").focus()
    },
    content: ({ goTo }) => {
      return (
        <SimpleStep
          content={
            <>
              <Paragraph>{t("add_client_step_3_line_1")}</Paragraph>
              <Paragraph>{t("add_client_step_3_line_2")}</Paragraph>
              <Paragraph>{t("add_client_step_3_line_3")}</Paragraph>
            </>
          }
          onPrevStep={() => goTo(1)}
          onNextStep={() => goTo(3)}
        />
      )
    }
  },
  {
    selector: "[data-tour='add-client-step-3']",
    content: ({ goTo }) => {
      return (
        <SimpleStep
          content={
            <>
              <Paragraph>{t("add_client_step_4_line_1")}</Paragraph>
              <Paragraph>{t("add_client_step_4_line_2")}</Paragraph>
            </>
          }
          onPrevStep={() => goTo(2)}
          onNextStep={() => goTo(4)}
        />
      )
    }
  },
  {
    selector: "[data-tour='add-client-step-4']",
    content: ({ goTo }) => {
      return (
        <SimpleStep
          content={
            <>
              <Paragraph>{t("add_client_step_5_line_1")}</Paragraph>
              <Paragraph>{t("add_client_step_5_line_2")}</Paragraph>
            </>
          }
          onPrevStep={() => goTo(3)}
        />
      )
    }
  }
]
