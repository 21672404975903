import TagManager, { TagManagerArgs } from "react-gtm-module"

import { hitTagManager } from "utils/gtm"

const tagManagerAccArgs: TagManagerArgs = {
  gtmId: "GTM-5L2VFMZ",
  auth: "kvC9KFcIeZrxjuT-rp0ixw",
  preview: "env-573"
}

const tagManagerPreprodArgs: TagManagerArgs = {
  gtmId: "GTM-5L2VFMZ",
  auth: "jLtO1Id79EwK8A8F-jW1Ng",
  preview: "env-68"
}

const tagManagerProdArgs: TagManagerArgs = {
  gtmId: "GTM-5L2VFMZ"
}

const initTagManager = () => {
  if (!isTagManager) {
    return
  }

  const tgmArgs =
    process.env.ENVIRONMENT === "acceptance"
      ? tagManagerAccArgs
      : process.env.ENVIRONMENT === "preprod"
        ? tagManagerPreprodArgs
        : tagManagerProdArgs

  TagManager.initialize(tgmArgs)
  hitTagManager()
}

export const isTagManager =
  process.env.NODE_ENV !== "development" &&
  (process.env.ENVIRONMENT === "acceptance" ||
    process.env.ENVIRONMENT === "preprod" ||
    process.env.ENVIRONMENT === "prod")

export default initTagManager
